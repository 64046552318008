import { ESIGN } from 'link-paths'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PathWithWildCard } from 'utils'

interface UnauthenticatedRoutingProps {
  returnUrl: string
}
// eslint-disable-next-line import/no-default-export
export default function UnauthenticatedRouting({
  returnUrl,
}: UnauthenticatedRoutingProps) {
  const UnauthenticatedLayout = React.lazy(
    () => import('components/layout/UnauthenticatedLayout')
  )
  const DocumentSign = React.lazy(() => import('pages/DocumentSign'))
  const LandingPage = React.lazy(() => import('pages/LandingPage'))

  return (
    <React.Suspense fallback={<div></div>}>
      <Routes>
        <Route
          path={PathWithWildCard(ESIGN)}
          element={
            <UnauthenticatedLayout>
              <DocumentSign />
            </UnauthenticatedLayout>
          }
        />
        <Route path={'/'} element={<LandingPage returnUrl={returnUrl} />} />
      </Routes>
    </React.Suspense>
  )
}
