import React, { useEffect, useState } from 'react'
import ga4 from 'react-ga4'

import { useLocation } from 'react-router-dom'

interface GoogleAnalyticsProps {
  trackPathnameOnly: boolean
  id: string | undefined
}

// eslint-disable-next-line import/no-default-export
export default function GoogleAnalytics({
  children,
  trackPathnameOnly,
  id,
}: React.PropsWithChildren<GoogleAnalyticsProps>) {
  const location = useLocation()
  const [lastPathname, setLastPathname] = useState<string>()

  ga4.initialize(id ?? '')

  useEffect(() => {
    // Do nothing if trackPathnameOnly is enabled and the pathname didn't change.
    if (trackPathnameOnly && location.pathname === lastPathname) {
      return
    }

    setLastPathname(location.pathname)

    ga4.send({
      hitType: 'pageview',
      page: location.pathname,
    })
  }, [lastPathname, location, trackPathnameOnly])

  return <>{children}</>
}
